<template>
  <div>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '8'">
        <span class="grey--text text--darken-2 text-h5 pa-0 mt-4">
          {{ title }}
        </span>

        <div class="subheading mt-2">
          <span class="grey--text text--darken-2 font-weight-bold">
            Descrição</span
          >
          <p class="pl-2" v-html="description" />
          <p class="mt-4">
            <span class="grey--text text--darken-2 font-weight-bold">
              Prazo em dias úteis:
            </span>
            {{ orderService.deadline }}
          </p>
        </div>

        <v-alert
          v-if="pendingPayment"
          border="left"
          color="red"
          elevation="7"
          outlined
          class="my-4"
        >
          <span class="text-h6"> Aguardando pagamento da Fataura: </span>
          <br />
          <a target="_black" :href="orderService.invoiceLink">
            {{ orderService.invoiceLink }}
          </a>
          <br />
        </v-alert>

        <v-row class="subheading mb-1">
          <v-col cols="12">
            <span class="grey--text text--darken-2 font-weight-bold"
              >Quantidade solicitada:
            </span>
            <span>{{ orderService.qtd }}</span>
          </v-col>
          <v-col cols="12">
            <span class="grey--text text--darken-2 font-weight-bold"
              >Total:
            </span>
            <span>{{ orderService.valueCents | currencyFromCents }}</span>
          </v-col>
        </v-row>

        <div class="subheading mt-6" v-if="orderService.files.length > 0">
          <span class="grey--text text--darken-2 font-weight-bold"
            >Arquivos Gerados</span
          >
          <v-col
            cols="12"
            md="7"
            v-for="(file, index) in orderService.files"
            :key="index"
            class="mb-2 elevation-5 px-4 py-2"
            align-self="center"
          >
            <k-download class="mr-2" :id="file.id"></k-download>
            {{ file.name }}
          </v-col>
        </div>

        <v-row no-gutters v-if="orderService.orderStep.name === 'CONTRACTED'">
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2" class="text-h6">
            <span class="grey--text text--darken-2 font-weight-bold">
              Status
            </span>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.smAndDown ? '12' : '8'"
            class="mt-2"
          >
            <k-progress-slider
              :value="parseInt(orderService.statusPercentage)"
            />
          </v-col>
        </v-row>
        <p v-else>
          <span class="grey--text text--darken-2 font-weight-bold"
            >Status:</span
          >
          {{ orderService.orderStep.namePtBy }}
        </p>

        <span class="grey--text text--darken-2 font-weight-bold mt-3">
          Interações/Comentarios
        </span>
        <comments
          v-for="item in orderService.comments"
          :comment="item"
          :key="item.id"
        />

        <div class="d-flex flex-row mb-6 justify-space-between mt-10">
          <v-btn
            rounded
            color="grey darken-1"
            dark
            :to="{ name: 'AdditionalServices' }"
          >
            Voltar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import kDownload from '@src/components/widget/Download.vue'
import comments from '@components/comments/comments.vue'
const kProgressSlider = () =>
  import('@src/components/widget/ProgressSlider.vue')

export default {
  name: 'detail-service',
  props: {
    orderService: {
      type: Object,
      default: () => {
        return {}
      },
      required: true,
    },
  },
  components: {
    kDownload,
    kProgressSlider,
    comments,
  },
  data() {
    return {
      title: this.orderService.planConfigActivity.name,
    }
  },
  computed: {
    ...mapState({}),
    pendingPayment() {
      return this.orderService.orderStep.name === 'AWAITING_PAYMENT'
    },
    description() {
      return this.$options.filters.whiteSpace(
        this.orderService.planConfigActivity.description
      )
    },
  },
  methods: {},
}
</script>
<style scoped></style>
