<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '8'">
        <span class="grey--text text--darken-2 text-h5 pa-0 mt-4">
          {{ title }}
        </span>
        <div class="subheading mt-2">
          <span class="grey--text text--darken-2 font-weight-bold">
            Descrição
          </span>
          <p class="pl-2" v-html="description" />
          <p class="mt-4">
            <span class="grey--text text--darken-2 font-weight-bold">
              Prazo em dias úteis:
            </span>
            {{ orderService.deadline }}
          </p>
        </div>
        <v-row class="subheading">
          <v-col cols="12" md="3">
            <span class="font-weight-medium">Quantidade: </span>
            <span>{{ orderService.qtd }}</span>
          </v-col>
          <v-col cols="12">
            <span class="font-weight-medium">Total: </span>
            <span>{{ orderService.valueCents | currencyFromCents }}</span>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col>
            <span class="grey--text text--darken-2 font-weight-bold">
              Interações/Comentarios
            </span>
          </v-col>
          <v-col cols="12">
            <comments
              v-for="item in orderService.comments"
              :comment="item"
              :key="item.id"
            />
          </v-col>
        </v-row>

        <div class="subheading mt-6">
          <p class="font-weight-medium mb-1">Seu comentário:</p>
          <v-textarea
            class=""
            label="O que esta precisando?"
            v-model="orderServiceForm.comment"
            required
            auto-grow
          >
          </v-textarea>
        </div>
        <span>Confirmar Aprovação</span>
        <v-row justify="space-between">
          <v-col cols="12" sm="12" md="12" lg="6">
            <v-text-field
              ref="name"
              label="Inserir Email de Acesso"
              v-model="orderServiceForm.email"
              required
              hide-details
              :rules="rules.email"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="5">
            <v-text-field
              ref="password"
              label="Inserir Senha de Acesso"
              :append-icon="pass ? 'visibility' : 'visibility_off'"
              @click:append="() => (pass = !pass)"
              :type="pass ? 'password' : 'text'"
              v-model="orderServiceForm.password"
              required
              hide-details
              :rules="rules.password"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-show="error" class="py-2">
            <p v-for="(item, index) in error" :key="index">
              <span class="error--text"
                ><v-icon color="error" small>priority_high</v-icon>
                {{ item }}</span
              >
            </p>
          </v-col>
        </v-row>

        <div class="d-flex flex-row mb-6 justify-space-between mt-10">
          <v-btn
            rounded
            color="grey darken-1"
            dark
            :to="{ name: 'AdditionalServices' }"
          >
            Voltar
          </v-btn>
          <v-btn
            rounded
            color="success"
            dark
            @click="aproveOrderService()"
            :loading="loading"
          >
            Contratar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { mapState } from 'vuex'
import comments from '@components/comments/comments.vue'

export default {
  name: 'detail-service',
  props: {
    orderService: {
      type: Object,
      default: () => {
        return {}
      },
      required: true,
    },
  },
  components: {
    comments,
  },
  data() {
    return {
      orderServiceForm: {
        comment: '',
        email: '',
        password: '',
      },
      pass: true,
      error: [],
      credentialsValid: false,
      valid: true,
      loading: false,
      rules: {
        password: [
          (v) => !!v || 'Por favor preencha a senha',
          (v) => !this.credentialsValid || 'Email ou senha invalido',
        ],
        email: [
          (v) =>
            /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            'Email precisa ser válido.',
          (v) => !this.credentialsValid || 'Email ou senha invalido',
        ],
      },
    }
  },
  computed: {
    title() {
      return this.orderService.planConfigActivity.name
    },
    description() {
      return this.$options.filters.whiteSpace(
        this.orderService.planConfigActivity.description
      )
    },
  },
  methods: {
    errorMessages() {
      this.$refs.form.$data.inputs.forEach((input) => {
        if (input.errorBucket.length > 0) this.error.push(...input.errorBucket)
      })
    },
    aproveOrderService() {
      this.error = []
      this.credentialsValid = false

      if (this.$refs.form.validate()) {
        this.loading = true
        let data = {
          id: this.orderService.id,
          user: this.orderServiceForm.email,
          password: this.orderServiceForm.password,
          commentUser: this.orderServiceForm.comment,
        }
        this.$store
          .dispatch('additionalService/aproveOrderService', data)
          .then(() => {
            this.loading = false
            this.$router.go(this.$router.currentRoute)
          })
          .catch((res) => {
            this.loading = false
            if (res.errors.hasOwnProperty('user')) {
              this.credentialsValid = true
              this.$refs.form.validate()
              this.error.push('Email ou senha invalido')
            }
          })
      } else {
        this.errorMessages()
      }
    },
  },
}
</script>
<style scoped></style>
