<template>
  <v-row class="pa-3" no-gutters>
    <v-col cols="12">
      <div class="text-body-2">
        <span class="pr-4">{{ name }}</span>
        <span>{{ comment.createdAt | moment('DD/MM/YYYY H:MM') }}</span>
      </div>
    </v-col>
    <v-col class="mt-2">
      <div v-html="body"></div>
      <v-divider class="my-2"></v-divider>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    comment: {
      type: Object,
      default: () => {
        return {
          id: null,
          userId: null,
          userAdminId: null,
          comment: null,
          shareable: false,
          createdAt: '',
          updatedAt: '',
          userName: null,
          userAdminName: null,
        }
      },
      required: true,
    },
  },
  computed: {
    name() {
      return this.comment.userName || this.comment.userAdminName
    },
    body() {
      return this.$options.filters.whiteSpace(this.comment.comment)
    },
  },
}
</script>
