<template>
  <div class="pl-6 pr-4 pt-2">
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
    <div v-else>
      <aprove-service
        :orderService="orderService"
        v-if="!!isAprove"
      ></aprove-service>
      <detail-service
        :orderService="orderService"
        v-if="!isAprove"
      ></detail-service>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import detailService from '@src/components/additionalServices/detailService.vue'
import aproveService from '@src/components/additionalServices/aproveService.vue'

export default {
  name: 'detail-service-view',
  components: {
    detailService,
    aproveService,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      orderService: 'additionalService/orderService',
    }),
    isAprove() {
      return this.orderService.orderStep.name === 'AWAITING_FOR_USER_APPROVAL'
    },
  },
  methods: {
    getOrder() {
      if (this.$route.params.idOrderService) {
        this.$store
          .dispatch(
            'additionalService/getOrderService',
            this.$route.params.idOrderService
          )
          .then((result) => {
            this.loading = false
          })
          .catch((err) => {})
      }
    },
  },
  mounted() {
    this.getOrder()
  },
}
</script>
<style scoped></style>
